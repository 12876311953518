import React from 'react';
import '../App.css';
import './Main.css';

function Main() {
  return (
    <div className='main-container'>
      <h1>Mount Royal Bagel Factory</h1>
      <p>1551 Sutherland Ave</p> 
      <p>Kelowna BC</p>
      <p>V1Y 9M9</p>

      <p>Monday - Friday, 8:00 am - 3:00 pm</p>
    </div>

    
  );
}

export default Main;